import $ from 'jquery';

class ToggleHeaderNav {
  constructor(el) {
    this._toggle(el);
  }

  _toggle(el) {
    const headerNav = $('#header-nav');
    let isHeaderNavOpen = false;

    $(el).on('click', function() {
      if (window.innerWidth >= 960) {
        return false;
      }

      headerNav.slideToggle();
      isHeaderNavOpen = !isHeaderNavOpen;

      return false;
    });

    $(document).click(function(){
      if (isHeaderNavOpen && window.innerWidth >= 960) {
        headerNav.slideUp();
      }
    });

    /* Clicks within the dropdown won't make
       it past the dropdown itself */
    headerNav.click(function(e){
      e.stopPropagation();
    });
  }
}

export default ToggleHeaderNav;
