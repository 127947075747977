import $ from 'jquery';

class ToolsUpsell {
  constructor(el) {
    this._toggle(el);
  }

  _toggle(el) {
    $(el).on('click', function() {
      $('[data-tools-upsell]').removeClass('is-active');
      $(this).addClass('is-active');
    });
  }
}

export default ToolsUpsell;
